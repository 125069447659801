/* import __COLOCATED_TEMPLATE__ from './multilingual-workflow-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type WorkflowLocalizationService from 'embercom/services/workflow-localization-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  readOnly?: boolean;
}

export default class MultilingualWorkflowDropdown extends Component<Args> {
  @service declare contentEditorService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare workflowLocalizationService: WorkflowLocalizationService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get toggleText() {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    return this.workflowLocalizationService.isMultilingualEnabled ? 'On' : 'Off';
  }

  get toggleColor() {
    return this.workflowLocalizationService.isMultilingualEnabled ? 'green' : 'yellow';
  }

  get languageHeading() {
    return this.args.readOnly
      ? this.intl.t('outbound.content-editor.headers.view-mode.workflow-header.preview-in')
      : this.intl.t('operator.workflows.visual-builder.auto-translate-tooltip-edit-mode');
  }

  get groupedLanguages() {
    let availableLocales = this.workflowLocalizationService.availableLocalesForEditor;
    let defaultLocaleId = this.workflowLocalizationService.defaultLocaleId;
    let defaultLanguage = availableLocales.find((locale) => locale.localeId === defaultLocaleId);
    let otherLanguages = availableLocales.filter((locale) => locale.localeId !== defaultLocaleId);
    let selectedLocaleId = this.workflowLocalizationService.selectedLocaleId;

    this.workflowLocalizationService.checkLocalizationStatus();

    return [
      ...[
        {
          heading: this.intl.t(
            'operator.workflows.visual-builder.workflow-trigger-node.multilingual-workflow',
          ),
          component: 'workflows/graph-editor/trigger-info-node/multilingual-workflow-toggle',
          componentAttrs: { readOnly: this.args.readOnly },
          items: [],
        },
      ],
      {
        heading: this.languageHeading,
        items: this.args.readOnly
          ? [
              {
                text: `${defaultLanguage?.name} ${this.intl.t(
                  'outbound.content-editor.headers.view-mode.workflow-header.default-language',
                )}`,
                value: defaultLanguage?.localeId,
                isLoading: false,
                isDisabled: false,
                isSelected: selectedLocaleId === defaultLanguage?.localeId,
                component: 'content-editor/headers/view-mode/workflow-language-option',
              },
              ...otherLanguages.map((locale) => ({
                text: locale.name,
                value: locale.localeId,
                isLoading: this.args.readOnly && this.workflowLocalizationService.isLoadingLocales,
                isDisabled:
                  !this.args.readOnly || this.workflowLocalizationService.isLoadingLocales,
                isSelected: selectedLocaleId === locale.localeId,
                component: 'content-editor/headers/view-mode/workflow-language-option',
              })),
            ]
          : [],
      },
    ];
  }

  @action
  selectLanguage(language: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'workflow_language_selector',
      language_selected: language,
    });

    this.workflowLocalizationService.selectLanguage(language);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'workflows/graph-editor/multilingual-workflow-dropdown': typeof MultilingualWorkflowDropdown;
    'Workflows::GraphEditor::MultilingualWorkflowDropdown': typeof MultilingualWorkflowDropdown;
  }
}
